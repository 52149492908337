import React, { useEffect, useState } from "react";
import {
  Datagrid,
  DateField,
  List,
  Loading,
  TextField,
  Filter,
  TextInput,
} from "react-admin";
import { ComponentProps } from "../../providers/Interfaces";
import { exportToExcel } from "../../utils/Helper";

const fields = [
  "id",
  "user",
  "created_at",
  "email",
  "employee_number",
  "industry_partner",
  "store",
  "type",
  "order_nr",
  "event_name",
];

const CartsFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="search" alwaysOn />
  </Filter>
);

export const CartsOverviewList = (props: ComponentProps): JSX.Element => {
  const { permissions } = props;
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (permissions !== undefined) {
      setLoading(false);
    }
  }, [permissions]);

  if (loading) return <Loading />;

  return (
    <List
      {...props}
      bulkActionButtons={false}
      exporter={(rec: any) => exportToExcel(rec, fields, "Carts")}
      sort={{ field: "id" }}
      title="Carts"
      filters={<CartsFilter />}
    >
      <Datagrid rowClick="show">
        <TextField source="id" />
        <TextField label="Industry Partner" source="industry_partner" />
        <TextField label="Store" source="store" />
        <TextField label="Cart Type" source="type" />
        <TextField label="Device" source="device" />
        <TextField label="Event Name" source="event_name" />
        <DateField
          source="created_at"
          locales="en-DE"
          options={{
            hour: "2-digit",
            minute: "2-digit",
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour12: false,
          }}
          showTime
        />
      </Datagrid>
    </List>
  );
};
